// BillingOptions.tsx

import React, { useState, useEffect } from 'react';
import { createCheckoutSession, getPlans, Plan, requestMagicLink } from '../services/apiService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

interface BillingOptionsProps {
  isAuthenticated: boolean;
  email?: string;
  handleGoogleLogin?: (state: { [key: string]: any }) => void;
}

const BillingOptions: React.FC<BillingOptionsProps> = ({ isAuthenticated, email, handleGoogleLogin }) => {
  const [plans, setPlans] = useState<Plan[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPlanId, setSelectedPlanId] = useState<number | null>(null);
  const [modalEmail, setModalEmail] = useState('');

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const data = await getPlans();
        setPlans(data);
      } catch (error) {
        console.error('Error loading plans:', error);
      }
    };

    fetchPlans();
  }, []);

  const handleSelectPlan = async (planId: number) => {
    if (isAuthenticated) {
      if (email) {
        const session = await createCheckoutSession(email, planId, '/apps', '/pricing');
        window.location.href = session.url;
      } else {
        console.error('User is authenticated but email not provided');
      }
    } else {
      setSelectedPlanId(planId);
      setIsModalOpen(true);
    }
  };

  const handleMagicLinkModalSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    
    if (selectedPlanId !== null) {
      await requestMagicLink(modalEmail);
      const session = await createCheckoutSession(modalEmail, selectedPlanId, '/auth/verify', '/');
      setIsModalOpen(false);
      window.location.href = session.url;
    }
  };

  const renderPricingCard = (plan: Plan, index: number) => {
    const intervalText = plan.interval === 'month' ? 'Per Month' : 
                         plan.interval === 'year' ? 'Per Year' : 
                         plan.interval === 'forever' ? 'Forever' : 
                         '' + plan.interval.charAt(0).toUpperCase() + plan.interval.slice(1);

    const isRecommended = index === 1; // Assuming the middle plan is recommended

    return (
      <div key={plan.id} className={`bg-white rounded-lg shadow-md p-6 flex flex-col relative ${isRecommended ? 'border-2 border-yellow-700' : ''}`}>
        {isRecommended && (
          <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-yellow-700 text-white px-4 py-1 rounded-full text-sm font-semibold">
            Recommended
          </div>
        )}
        <h3 className="text-2xl font-bold mb-2 text-gray-900 dark:text-gray-100"> 
          {plan.name}
        </h3>
        <div className="flex items-baseline mb-4">
          <span className="text-5xl font-bold text-gray-900">${(plan.price / 100)}</span>
          <span className="text-gray-700 ml-2">{intervalText}</span>
        </div>
        <hr className="mb-4" />
        <ul className="mb-6 flex-grow">
          {plan.features.map((feature, index) => (
            <li key={index} className="flex items-center mb-2">
              <FontAwesomeIcon icon={faCheck} className="text-green-600 mr-2" />
              <span className="text-gray-800">{feature}</span>
            </li>
          ))}
        </ul>
        <button
          className={`w-full py-2 rounded-md font-semibold ${
            plan.name === 'Standard' 
              ? 'bg-gray-800 text-white hover:bg-gray-700' 
              : 'bg-white text-gray-800 border border-gray-400 hover:bg-gray-50'
          }`}
          onClick={() => handleSelectPlan(plan.id)}
          aria-label={`${plan.name === 'Free' ? 'Get started with' : 'Subscribe to'} ${plan.name} plan`}
        >
          {plan.name === 'Free' ? 'Get started' : 'Subscribe'}
        </button>
        {plan.name === 'Free' && (
          <p className="mt-4 text-sm text-gray-500">
            Chatbots get deleted after 14 days of inactivity on the free plan.
          </p>
        )}
      </div>
    );
  };

  return (
    <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
      <div className="grid md:grid-cols-3 gap-8">
        {plans.map(renderPricingCard)}
      </div>

      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
          <div className="bg-white dark:bg-gray-800 p-8 rounded-xl shadow-2xl w-full max-w-md relative transform transition-all duration-300 ease-in-out">
            <button
              className="absolute top-4 right-4 text-gray-400 hover:text-gray-600 dark:text-gray-300 dark:hover:text-gray-100 transition-colors duration-200"
              onClick={() => setIsModalOpen(false)}
              aria-label="Close modal"
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
            <h3 className="text-2xl font-bold mb-6 text-gray-800 dark:text-gray-100">Join ChimeInfluence</h3>
            <button 
              onClick={() => handleGoogleLogin && handleGoogleLogin({ 'plan_id': selectedPlanId })} 
              className="btn w-full flex items-center justify-center space-x-2 bg-white dark:bg-gray-700 text-gray-700 dark:text-gray-200 border border-gray-300 dark:border-gray-600 hover:bg-gray-50 dark:hover:bg-gray-600 transition-colors duration-200 py-2 px-4 rounded-lg"
              aria-label="Continue with Google"
            >
              <svg className="h-5 w-5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z" fill="#4285F4"/>
                <path d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z" fill="#34A853"/>
                <path d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z" fill="#FBBC05"/>
                <path d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z" fill="#EA4335"/>
                <path d="M1 1h22v22H1z" fill="none"/>
              </svg>
              <span>Continue with Google</span>
            </button>
            <div className="relative my-8">
              <hr className="border-gray-300 dark:border-gray-600" />
              <span className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white dark:bg-gray-800 px-4 text-sm text-gray-500 dark:text-gray-400">
                or
              </span>
            </div>
            <form onSubmit={handleMagicLinkModalSubmit} className="space-y-6">
              <div>
                {/* <label htmlFor="email" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">Email address</label> */}
                <input
                  id="email"
                  className="input input-bordered w-full px-4 py-2 rounded-lg border border-gray-300 dark:border-gray-600 focus:ring-2 focus:ring-blue-500 dark:bg-gray-700 dark:text-white"
                  placeholder="Enter your email"
                  type="email"
                  value={modalEmail}
                  onChange={(e) => setModalEmail(e.target.value)}
                  required
                />
              </div>
              <button className="btn btn-primary w-full py-2 px-4 rounded-lg text-white bg-blue-600 hover:bg-blue-700 transition-colors duration-200" type="submit">
                Chime in now →
              </button>
              <p className="text-sm text-gray-600 dark:text-gray-400 text-center">If you already have an account, we'll log you in.</p>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default BillingOptions;