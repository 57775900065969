import React from 'react';
import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Logo from '../assets/logo.png';

const Footer: React.FC = () => {
  return (
    <footer className="text-left p-4 mt-10 dark:bg-slate-900 flex justify-between items-center">
      <div>
        <h2 className="text-2xl font-bold flex items-center">
          <LazyLoadImage className="w-10" alt="ChimeInfluence Logo" src={Logo} />   
          <Link to="/" className="hover:text-primary-focus">CHIME INFLUENCE</Link>
        </h2>
      </div>
      <nav className="flex space-x-4" aria-label="Footer navigation">
        <Link to="/terms" className="text-lg font-semibold hover:text-primary-focus">Terms of Service</Link>
        <Link to="/privacy" className="text-lg font-semibold hover:text-primary-focus">Privacy Policy</Link>
      </nav>
    </footer>
  );
};

export default Footer;
