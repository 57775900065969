import { useState } from "react";

const FAQ = () => {
    const [faqData] = useState([
      {
        question: "What is ChimeInfluence?",
        answer: "ChimeInfluence is an AI-driven platform that helps brands monitor and engage in relevant online conversations, enhancing visibility and fostering authentic connections with their audience."
      },
      {
        question: "How does ChimeInfluence work?",
        answer: "ChimeInfluence utilizes advanced AI and natural language processing to identify key conversations across social media platforms. It allows brands to respond intelligently and organically, ensuring meaningful engagement."
      },
      {
        question: "Who can benefit from using ChimeInfluence?",
        answer: "ChimeInfluence is ideal for businesses of all sizes looking to enhance their online presence and engage effectively with potential customers through relevant conversations."
      },
      {
        question: "Which platforms does ChimeInfluence support?",
        answer: "ChimeInfluence integrates with various social media platforms where conversations occur, including popular sites like Twitter and Reddit. The specific platforms available depend on your subscription level."
      },
      {
        question: "Is there a free trial available?",
        answer: "Yes, we offer a free trial that allows you to experience the full capabilities of ChimeInfluence. During this period, you can evaluate how the platform enhances your brand engagement."
      },
      {
        question: "How does billing work for ChimeInfluence?",
        answer: "We provide flexible billing options, including monthly and annual subscription plans. Pricing is based on factors such as interaction volume and the number of integrated platforms."
      },
      {
        question: "How does ChimeInfluence ensure interactions are not spammy?",
        answer: "Our AI is designed to understand context and sentiment, ensuring that interactions are relevant and appropriate. We have strict guidelines and filters to prevent spammy content, continuously improving over time."
      },
      // {
      //   question: "How can I measure the effectiveness of ChimeInfluence for my brand?",
      //   answer: "ChimeInfluence provides comprehensive analytics and reporting features. You can track key metrics such as engagement rates, click-throughs, sentiment analysis, and conversion rates attributed to ChimeInfluence interactions."
      // },
    ]);
  
    return (
      <div className="mx-auto mt-8 grid max-w-xl divide-y divide-neutral-200 px-4 sm:px-8">
        {faqData.map((faq, index) => (
          <div key={index} className="py-5">
            <details className="group">
              <summary className="flex cursor-pointer list-none items-left justify-between font-medium" aria-label={`FAQ question: ${faq.question}`}>
                <h3 id={`faq-question-${index}`} className="text-gray-800">{faq.question}</h3> {/* Changed to a darker text color */}
                <span className="transition group-open:rotate-180" aria-hidden="true">
                  <svg
                    fill="none"
                    height="24"
                    shapeRendering="geometricPrecision"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                    viewBox="0 0 24 24"
                    width="24"
                  >
                    <path d="M6 9l6 6 6-6"></path>
                  </svg>
                </span>
              </summary>
              <p className="group-open:animate-fadeIn mt-3 text-left text-neutral-600" aria-labelledby={`faq-question-${index}`}>
                {faq.answer}
              </p>
            </details>
          </div>
        ))}
      </div>
    );
  };

export default FAQ;

