// apiService.ts
const apiUrl = process.env.REACT_APP_API_URL;
const frontendUrl = process.env.REACT_APP_FRONTEND_URL
// Auth 

export const googleAuth = async (code: string) => {
  const response = await fetch(`${apiUrl}/auth/google?code=${code}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    // body: JSON.stringify({ code }),
  });

  if (!response.ok && response.status === 400) {
    return null;
  }

  return response.json();
};

export const requestMagicLink = async (email: string) => {
  const response = await fetch(`${apiUrl}/auth/request-magic-link`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ 'email': email }),
  });

  if (!response.ok) {
    throw new Error('Failed to request magic link');
  }

  return response.json();
};

export const verifyMagicLink = async (token: string, email: string) => {
  const response = await fetch(`${apiUrl}/auth/verify-magic-link?token=${token}&email=${email}`, {
    method: 'GET',
  });

  if (!response.ok) {
    throw new Error('Failed to verify magic link.');
  }

  return response.json();
};

// Apps
export interface AppData {
  id: string;
  name: string;
  url: string;
  desc: string;
  keywords: string[];
  gen_keywords: string[];
  settings: Record<string, any>;
}


export const getApp = async (id: string) => {
  const response = await fetch(`${apiUrl}/apps/${id}`, {
    method: 'GET',
  });

  if (!response.ok) {
    throw new Error('Failed to get apps for uid.');
  }

  return response.json();
};

export const getApps = async (uid: string) => {
  const response = await fetch(`${apiUrl}/apps/by_uid/${uid}`, {
    method: 'GET',
  });

  if (!response.ok) {
    throw new Error('Failed to get apps for uid.');
  }

  return response.json();
};

export const createApp = async (app: AppData): Promise<AppData> => {
  const response = await fetch(`${apiUrl}/apps`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(app),
  });

  if (!response.ok) {
    const errorResponse = await response.json();
    alert(errorResponse.message); 
    throw new Error('Failed to create app for uid.');
  }

  return response.json() as Promise<AppData>; // Ensure TypeScript knows the return type
};

export const deleteApp = async (id: string) => {
  const response = await fetch(`${apiUrl}/apps/${id}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    }
  });

  if (!response.ok) {
    throw new Error('Failed to delete app for uid.');
  }

  return response.json();
};

export const updateApp = async (id: string, app: {}): Promise<AppData> => {
  const response = await fetch(`${apiUrl}/apps/${id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(app),
  });

  if (!response.ok) {
    throw new Error('Failed to update app for uid.');
  }

  return response.json();
};

// Posts

export interface SocialMediaPost {
  id: string;
  aid: string;
  uid: string;
  username: string;
  title: string;
  text: string;
  created_at: string;
  like_count: number;
  impression_count: number;
  platform_post_id: string;
  platform: string;
  reply: string;
  replied: boolean;
}
interface PaginatedPostsResponse {
  posts: SocialMediaPost[];
  page: number;
  total_pages: number;
  total_posts: number;
}

export interface PostsResult {
  posts: SocialMediaPost[];
  page: number;
  totalPages: number;
  totalPosts: number;
  hasMore: boolean;
}

export const getPosts = async (aid: string, page: number = 1, limit: number = 10): Promise<PostsResult> => {
  try {
    const response = await fetch(`${apiUrl}/posts/${aid}?page=${page}&limit=${limit}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        // Add any other headers you might need, like authentication tokens
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data: PaginatedPostsResponse = await response.json();

    return {
      posts: data.posts,
      page: data.page,
      totalPages: data.total_pages,
      totalPosts: data.total_posts,
      hasMore: data.page < data.total_pages
    };
  } catch (error) {
    console.error('Error fetching posts:', error);
    throw error;
  }
};

export const updatePost = async (id: string, post: SocialMediaPost): Promise<SocialMediaPost> => {
  const response = await fetch(`${apiUrl}/posts/${id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(post),
  });

  if (!response.ok) {
    throw new Error('Failed to update post.');
  }

  return response.json();
};

// Update the GeneratedReply interface
export interface GeneratedReply {
  reply: string;
}

// Update the generateReply function
export const generateReply = async (postId: string, appId: string): Promise<GeneratedReply> => {
  try {
    const response = await fetch(`${apiUrl}/posts/generate-reply/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // Add any other headers you might need, like authentication tokens
      },
      body: JSON.stringify({ 'post_id': postId, 'aid': appId }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Error generating reply:', error);
    throw error;
  }
};

// Function to reply to a post
export const replyToPost = async (postId: string, platformPostId: string, reply: string, platform: string) => {
  try {
    const response = await fetch(`${apiUrl}/posts/${postId}/reply`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ 'reply': reply, 'post_id': platformPostId, 'platform': platform }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Error replying to post:', error);
    throw error;
  }
};

// Payment 

export interface Plan {
  id: number;
  name: string;
  description: string;
  price: number;
  currency: string;
  interval: string;
  subscription_id: string;
  features: string[];
  recommended: boolean;
};

export const getPlans = async (): Promise<Plan[]> => {

  const response = await fetch(`${apiUrl}/payment/plans`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (!response.ok) {
    throw new Error('Failed to fetch payment plans');
  }

  // Cast the response JSON to an array of Plan objects
  const plans: Plan[] = await response.json();
  return plans;
};


export const getUserBilling = async (uid: string): Promise<any> => {
  try {
    const response = await fetch(`${apiUrl}/user_billing/${uid}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        // Add any other headers you might need, like authentication tokens
      },
    });

    const data = await response.json();

    if (!response.ok) {
      // If the response is not OK, throw an error with the message from the server
      throw new Error(data.message || `HTTP error! status: ${response.status}`);
    }

    return data;
  } catch (error) {
    console.error('Failed to get user billing information:', error);
    throw error;
  }
};

export const createCheckoutSession = async (email: string, planId: number, successUrl: string, cancelUrl: string): Promise<any> => {
  try {
    const response = await fetch(`${apiUrl}/payment/create-checkout-session`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // Add any other headers you might need, like authentication tokens
      },
      body: JSON.stringify({ 'email': email, 
                             'plan_id': planId,
                             'success_url': frontendUrl + successUrl,
                             'cancel_url': frontendUrl + cancelUrl }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Failed to create checkout session:', error);
    throw error;
  }
};

export const createManageSubscriptionSession = async (uid: string, returnUrl: string): Promise<any> => {
  try {
    const response = await fetch(`${apiUrl}/payment/manage_subscription`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ uid, return_url: returnUrl }),
    });

    const data = await response.json();

    if (!response.ok) {
      // If the response is not OK, throw an error with the message from the server
      throw new Error(data.message || `HTTP error! status: ${response.status}`);
    }

    return data;
  } catch (error) {
    console.error('Failed to create manage subscription session:', error);
    throw error;
  }
};

// User stats

export const getUserStats = async (uid: string): Promise<any> => {
  try {
    const response = await fetch(`${apiUrl}/user_stats/${uid}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        // Add any other headers you might need, like authentication tokens
      },
    });

    const data = await response.json();

    if (!response.ok) {
      // If the response is not OK, throw an error with the message from the server
      throw new Error(data.message || `HTTP error! status: ${response.status}`);
    }

    return data;
  } catch (error) {
    console.error('Failed to get user stats information:', error);
    throw error;
  }
};
