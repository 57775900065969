import React, { lazy, Suspense, useEffect } from 'react';
import './styles/app.css';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';

// Import components that are needed for the initial route
import LandingPage from './pages/LandingPage';

// Lazy load other components
const Apps = lazy(() => import('./pages/Apps'));
const VerifyPage = lazy(() => import('./pages/VerifyPage'));
const VerifyAuth = lazy(() => import('./pages/VerifyAuth'));
const NotFoundPage = lazy(() => import('./pages/NotFoundPage'));
const Mentions = lazy(() => import('./pages/Mentions'));
const Privacy = lazy(() => import('./pages/Privacy'));
const Terms = lazy(() => import('./pages/Terms'));
const Blog = lazy(() => import('./pages/Blog'));

// Fallback spinner component
const Spinner = () => (
  <div className="flex flex-col items-center justify-center min-h-screen space-y-4">
    <h1 className="text-2xl font-bold">Loading...</h1>
    <div className="loading loading-spinner loading-lg text-yellow-500"></div>
    <span className="sr-only">Loading...</span>
  </div>
);

const AppContent: React.FC = () => {
  const location = useLocation();
  
  useEffect(() => {
    if (window.gtag) {
      window.gtag('config', 'G-HVJN12QSFW', {
        page_path: location.pathname,
      });
    }
  }, [location]);

  return (
    <Suspense fallback={<Spinner />}>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/pricing" element={<LandingPage section="pricing" />} />
        <Route path="/how-it-works" element={<LandingPage section="how-it-works" />} />
        <Route path="/faq" element={<LandingPage section="faq" />} />

        <Route path="/auth/verify" element={<VerifyPage />} />
        <Route path="/auth/verify-auth" element={<VerifyAuth />} />
        <Route path="/apps" element={<Apps />} />
        <Route path="/mentions" element={<Mentions />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Suspense>
  );
};

const App: React.FC = () => {
  return (
    <div className="App">
      <Router>
        <AppContent />
      </Router>
    </div>
  );
};

export default App;
export {};

