import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { requestMagicLink } from '../services/apiService';
import '../styles/app.css';
import { LazyLoadImage, LazyLoadComponent } from 'react-lazy-load-image-component';
import Logo from '../assets/logo.png';
import FAQ from '../components/FAQ';
import BillingOptions from '../components/BillingOptions';
import Footer from '../components/Footer';

const LandingPage = ({ section }: { section?: string }) => {
  const [email] = useState<string>('');
  const navigate = useNavigate();
  const isAuthenticated = !!localStorage.getItem('uid');
  const [, setIsCtaVisible] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalEmail, setModalEmail] = useState('');
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const steps = [
    { number: '01', title: 'Create Application', description: 'Set up your brand profile with ease.' },
    { number: '02', title: 'Add Keywords', description: 'Define relevant topics for your brand.' },
    { number: '03', title: 'Add Audience', description: 'Identify and target your ideal customers.' },
    { number: '04', title: 'Track Results', description: 'Monitor engagement and grow your presence.' },
  ];

  const handleGoogleLogin = (state: { [key: string]: any }) => {
    const googleAuthEndpoint = "https://accounts.google.com/o/oauth2/v2/auth";
    const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
    const redirectUri = process.env.REACT_APP_GOOGLE_REDIRECT_URI;
    const responseType = "code";
    const scope = "openid email profile";

    const authUrl = `${googleAuthEndpoint}?` +
      `client_id=${clientId}` +
      `&redirect_uri=${redirectUri}` +
      `&response_type=${responseType}` +
      `&scope=${scope}` +
      `&state=${encodeURIComponent(JSON.stringify(state))}`;
    window.location.href = authUrl;
  };

  const handleEmailSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      setIsCtaVisible(false);
      const ctaAction = document.getElementById('cta-action') as HTMLFormElement;
      ctaAction.innerHTML = '<span class="loading loading-spinner text-neutral"></span>';
      setIsCtaVisible(true);

      await requestMagicLink(email);
      navigate('/auth/verify');
      
    } catch (error) {
      console.log('Failed to send magic link. Please try again.');
    }
  };

  const handleMagicLinkModalSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      await requestMagicLink(modalEmail);
      navigate('/auth/verify');
      setIsModalOpen(false); // Close modal after submission
    } catch (error) {
      console.log('Failed to send magic link. Please try again.');
    }
  };

  const handleLoginClick = () => {
    setIsModalOpen(true);
  };

  useEffect(() => {
    if (section) {
      const element = document.getElementById(section);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [section]);

  return (
    <>
      <header className="fixed w-full top-0 bg-white dark:bg-gray-900 shadow-md z-50">
        <nav className="container mx-auto px-4 py-4">
          <div className="flex justify-between items-center">
            <h1 className="text-2xl font-bold flex items-center">
              <LazyLoadImage className="w-10" alt="ChimeInfluence Logo" src={Logo} effect="opacity" />
              <span className="hidden sm:inline">CHIME INFLUENCE</span>
            </h1>
            <div className="hidden md:flex items-center space-x-6">
              <a className="text-gray-700 dark:text-gray-300 hover:text-yellow-500 dark:hover:text-yellow-400 transition-colors" href="/how-it-works">How it Works</a>
              <a className="text-gray-700 dark:text-gray-300 hover:text-yellow-500 dark:hover:text-yellow-400 transition-colors" href="/pricing">Pricing</a>
              <a className="text-gray-700 dark:text-gray-300 hover:text-yellow-500 dark:hover:text-yellow-400 transition-colors" href="/faq">FAQ</a>
              {isAuthenticated ? (
                <Link className="bg-yellow-500 text-white hover:bg-yellow-600 transition-colors px-4 py-2 rounded-md font-medium" to="/apps">Dashboard</Link>
              ) : (
                <button className="border-2 border-yellow-500 text-yellow-500 hover:bg-yellow-500 hover:text-white transition-colors px-4 py-2 rounded-md font-medium" onClick={handleLoginClick} aria-label="Log In">Log In</button>
              )}
            </div>
            <button 
              className="md:hidden text-gray-700 dark:text-gray-300"
              onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
              aria-label={isMobileMenuOpen ? "Close menu" : "Open menu"}
            >
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
              </svg>
            </button>
          </div>
          {isMobileMenuOpen && (
            <div className="md:hidden mt-4">
              <ul className="bg-white dark:bg-gray-800 rounded-lg shadow-lg">
                <li><a className="block px-4 py-2 text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700" href="/how-it-works">How it Works</a></li>
                <li><a className="block px-4 py-2 text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700" href="/pricing">Pricing</a></li>
                <li><a className="block px-4 py-2 text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700" href="/faq">FAQ</a></li>
                <li>
                  {isAuthenticated ? (
                    <Link className="block px-4 py-2 text-yellow-500 hover:bg-yellow-500 hover:text-white" to="/apps">Dashboard</Link>
                  ) : (
                    <button className="block w-full text-left px-4 py-2 text-yellow-500 hover:bg-yellow-500 hover:text-white" onClick={handleLoginClick} aria-label="Log In">Log In</button>
                  )}
                </li>
              </ul>
            </div>
          )}
        </nav>
      </header>

      <main className="pt-16 bg-gray-50 dark:bg-gray-900">
        <LazyLoadComponent>
          <section className="hero py-20 bg-gradient-to-r from-gray-600 to-emerald-800 text-white">
            <div className="container mx-auto px-4 text-center">
              <h2 className="text-4xl md:text-6xl font-extrabold leading-tight mb-6 text-gray-100">
                Find People Who Already Want <br className="hidden md:block" />
                What You're Selling
              </h2>
              <p className="text-xl md:text-2xl mb-10 max-w-3xl mx-auto text-gray-200">
                ChimeInfluence empowers your brand to effortlessly engage in meaningful conversations, leveraging AI to respond to your audience in real-time and enhance your online presence.
              </p>
              <button 
                className="btn bg-white text-emerald-800 px-8 py-3 text-lg font-semibold rounded-full shadow-lg hover:shadow-xl transition-all duration-300 hover:bg-emerald-100" 
                onClick={() => setIsModalOpen(true)}
                aria-label="Get Started"
              >
                Get Started
              </button>
            </div>
          </section>
        </LazyLoadComponent>
        
        <section id="how-it-works" className="py-12 bg-white dark:bg-gray-800">
          <div className="container mx-auto px-4">
            <h2 className="text-3xl md:text-4xl font-bold text-center mb-8 text-gray-900 dark:text-gray-100">How ChimeInfluence Works for You</h2>
            <div className="grid md:grid-cols-2 gap-6">
              {steps.map((step, index) => (
                <div key={index} className="flex">
                  <div className="mr-4">
                    <span className="text-3xl font-bold text-gray-600 dark:text-gray-400">{step.number}</span>
                  </div>
                  <div>
                    <h3 className="text-xl font-bold mb-2 text-gray-900 dark:text-gray-100 flex items-center">
                      {step.title}
                      {index < steps.length - 1 && (
                        <svg className="w-5 h-5 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
                        </svg>
                      )}
                    </h3>
                    <p className="text-gray-700 dark:text-gray-200">{step.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        <section id="pricing" className="py-20 bg-gradient-to-b from-white to-gray-100 dark:from-gray-900 dark:to-gray-800">
          <div className="container mx-auto px-4">
            <div className="max-w-3xl mx-auto text-center mb-16">
              <h2 className="text-3xl md:text-5xl font-bold mb-6 text-gray-800 dark:text-gray-200">
                Tailored Solutions for <span className="text-yellow-500">Forward-Thinking Brands</span>
              </h2>
              {/* <div className="w-24 h-1 bg-yellow-500 mx-auto my-6"></div> */}
              <p className="text-xl text-gray-600 dark:text-gray-300">
                At ChimeInfluence, we harness the power of technology and innovation to help you unlock new opportunities and drive sustainable growth in your market.
              </p>
            </div>
            <BillingOptions 
              isAuthenticated={isAuthenticated} 
              email={localStorage.getItem('email') || undefined}
              handleGoogleLogin={handleGoogleLogin}
            />
          </div>
        </section>

        <section id="faq" className="py-20 bg-white dark:bg-gray-800">
          <div className="container mx-auto px-4">
            <h2 className="text-3xl md:text-4xl font-bold text-center mb-12 text-gray-800 dark:text-gray-200">Frequently Asked Questions</h2>
            <div className="max-w-3xl mx-auto">
              <FAQ />
            </div>
          </div>
        </section>

        <section className="cta py-20 bg-gradient-to-r from-gray-700 to-emerald-500 text-white">
          <div className="container mx-auto px-4 text-center">
            <h2 className="text-3xl md:text-4xl font-bold mb-6">Transform Conversations into Opportunities</h2>
            <p className="text-xl mb-10 max-w-2xl mx-auto">
              Streamline your social media strategy and let AI handle the engagement, so you can focus on what matters most—growing your brand.
            </p>
            {isAuthenticated ? (
              <Link className="btn bg-white text-emerald-700 px-8 py-3 text-lg font-semibold rounded-full shadow-lg hover:shadow-xl transition-all duration-300 hover:bg-emerald-100" to="/apps" aria-label="Go to Dashboard">
                Go to Dashboard
              </Link>
            ) : (
              <button className="btn bg-white text-emerald-700 px-8 py-3 text-lg font-semibold rounded-full shadow-lg hover:shadow-xl transition-all duration-300 hover:bg-emerald-100" onClick={handleLoginClick} aria-label="Get Started">
                Get Started
              </button>
            )}
          </div>
        </section>

        <Footer />
      </main>

      {/* Login Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
          <div className="bg-white dark:bg-gray-800 p-8 rounded-xl shadow-2xl w-full max-w-md relative transform transition-all duration-300 ease-in-out">
            <button
              className="absolute top-4 right-4 text-gray-400 hover:text-gray-600 dark:text-gray-300 dark:hover:text-gray-100 transition-colors duration-200"
              onClick={() => setIsModalOpen(false)}
              aria-label="Close login modal"
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
            <h3 className="text-2xl font-bold mb-6 text-gray-800 dark:text-gray-100">Join ChimeInfluence</h3>
            <form onSubmit={handleMagicLinkModalSubmit} className="space-y-6">
              <div>
                <input
                  id="email"
                  className="input input-bordered w-full px-4 py-2 rounded-lg border border-gray-300 dark:border-gray-600 focus:ring-2 focus:ring-blue-500 dark:bg-gray-700 dark:text-white"
                  placeholder="Enter your email"
                  type="email"
                  value={modalEmail}
                  onChange={(e) => setModalEmail(e.target.value)}
                  required
                />
              </div>
              <button className="btn btn-primary w-full py-2 px-4 rounded-lg text-white bg-blue-600 hover:bg-blue-700 transition-colors duration-200" type="submit" aria-label="Join the conversation today">
                Join the conversation today →
              </button>
              <p className="text-sm text-gray-600 dark:text-gray-400 text-center">If you already have an account, we'll log you in.</p>
            </form>
            <div className="relative my-8">
              <hr className="border-gray-300 dark:border-gray-600" />
              <span className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white dark:bg-gray-800 px-4 text-sm text-gray-500 dark:text-gray-400">
                or
              </span>
            </div>
            <button 
              onClick={() => handleGoogleLogin && handleGoogleLogin({})} 
              className="btn w-full flex items-center justify-center space-x-2 bg-white dark:bg-gray-700 text-gray-700 dark:text-gray-200 border border-gray-300 dark:border-gray-600 hover:bg-gray-50 dark:hover:bg-gray-600 transition-colors duration-200 py-2 px-4 rounded-lg"
              aria-label="Continue with Google"
            >
              <svg className="h-5 w-5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z" fill="#4285F4"/>
                <path d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z" fill="#34A853"/>
                <path d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z" fill="#FBBC05"/>
                <path d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z" fill="#EA4335"/>
                <path d="M1 1h22v22H1z" fill="none"/>
              </svg>
              <span>Continue with Google</span>
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default LandingPage;